import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  IconButton,
  Typography,
  MenuItem,
  ListItemText
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { FormQuestionMediaReq } from '../../proxy/proxy';
import { showAlert } from '../../store/actions/alertActions';
import { formatErrorMessage } from '../../utils/common';
import FormsService from '../../services/FormsService';

interface MediaInputProps {
  order: number;
  currentFieldState: FormQuestionMediaReq;
  fieldStateChanged: (fieldState: any, order: number) => void;
  deleteEl: (order: number) => void;
  handleArrowUpClick: (order: number) => void;
  handleArrowDownClick: (order: number) => void;
  duplicateElement: (order: number) => void;
}

const MediaInput: React.FC<MediaInputProps> = ({
  order,
  currentFieldState,
  fieldStateChanged,
  deleteEl,
  duplicateElement,
  handleArrowDownClick,
  handleArrowUpClick
}) => {
  const [fieldState, setFieldState] = useState<FormQuestionMediaReq>(currentFieldState);

  useEffect(() => {
    fieldStateChanged(fieldState, order);
  }, [fieldState]);

  return (
    <Fragment>
      <Paper elevation={2}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '10px',
            paddingBottom: '10px'
          }}
        >
          <Typography color="textPrimary" variant="h4">
            Media question
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              control={(
                <Switch
                  checked={fieldState.required || false}
                  onChange={() => {
                    setFieldState({ ...fieldState, required: !fieldState.required });
                  }}
                  name="required-field"
                  color="secondary"
                />
              )}
              label="Required"
              sx={{ ml: 2 }}
            />
            <IconButton
              sx={{
                borderRadius: '50%',
                padding: '10px',
                backgroundColor: '#f0f0f0',
                marginRight: '5px'
              }}
              onClick={() => handleArrowUpClick(order)}
            >
              <ArrowUpwardIcon sx={{ cursor: 'pointer' }} />
            </IconButton>
            <IconButton
              sx={{
                borderRadius: '50%',
                padding: '10px',
                backgroundColor: '#f0f0f0',
                marginRight: '5px'
              }}
              onClick={() => handleArrowDownClick(order)}
            >
              <ArrowDownwardIcon sx={{ cursor: 'pointer' }} />
            </IconButton>
            <IconButton
              sx={{
                borderRadius: '50%',
                padding: '10px',
                backgroundColor: '#f0f0f0',
                marginRight: '5px'
              }}
              onClick={() => deleteEl(order)}
            >
              <DeleteIcon sx={{ cursor: 'pointer' }} color="primary" />
            </IconButton>
            <IconButton
              sx={{
                borderRadius: '50%',
                padding: '10px',
                backgroundColor: '#f0f0f0'
              }}
              onClick={() => duplicateElement(order)}
            >
              <ContentCopyIcon sx={{ cursor: 'pointer' }} color="primary" />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ p: 3, mb: 4 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                value={fieldState.title}
                variant="filled"
                label="Text question title"
                onChange={(e) => {
                  setFieldState({ ...fieldState, title: e.target.value });
                }}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={fieldState.subTitle || ''}
                variant="filled"
                label="Text question subtitle"
                onChange={(e) => {
                  setFieldState({ ...fieldState, subTitle: e.target.value });
                }}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                value={fieldState.minimumFilesLimit || ''}
                variant="filled"
                type="number"
                label="Minimum files"
                onChange={(e) => {
                  setFieldState({ ...fieldState, minimumFilesLimit: Number(e.target.value) });
                }}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                value={fieldState.maximumFilesLimit || ''}
                variant="filled"
                type="number"
                label="Minimum files"
                onChange={(e) => {
                  setFieldState({ ...fieldState, maximumFilesLimit: Number(e.target.value) });
                }}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <input
                type="file"
                multiple // Allow multiple files
                required={fieldState.required || false} // Required field based on state
                disabled // Disabled state
                style={{ display: 'block', width: '100%' }} // Styling for full width
              />
              <Typography variant="subtitle1" gutterBottom>
                {`Upload between ${fieldState.minimumFilesLimit} and ${fieldState.maximumFilesLimit} files.`}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Fragment>
  );
};

export default MediaInput;
