import React, { useState, Fragment, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  TextField,
  Button,
  IconButton,
  Link,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { FormQuestionTextualReq, FormReq } from '../../proxy/proxy';

interface TextFieldPreviewProps {
  currentFieldState: FormQuestionTextualReq;
}

const TextFieldPreview: React.FC<TextFieldPreviewProps> = ({ currentFieldState }) => {
  const [fieldState, setFieldState] = useState<FormQuestionTextualReq>(currentFieldState);

  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  const validateInput = (value: string) => {
    const regex = new RegExp(fieldState.regularExpression ?? '');
    if (!regex.test(value)) {
      setError('Please enter a valid value');
    } else {
      setError('');
    }
  };

  const handleChange = (event: any) => {
    const { value } = event.target;
    setInputValue(value);
    validateInput(value);
  };

  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <Typography variant="h4" gutterBottom>
          {fieldState.title}
        </Typography>
        <TextField
          fullWidth
          name={fieldState.title}
          placeholder={fieldState.subTitle ?? ''}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          multiline={fieldState.isMultiline}
          required={fieldState.required}
          value={inputValue}
          onChange={handleChange}
          error={!!error}
          helperText={error}
        />
      </Grid>
    </Fragment>
  );
};

export default TextFieldPreview;
