import React, { useState, Fragment, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  TextField,
  Button,
  IconButton,
  Link,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { FormQuestionMediaReq, FormQuestionTextualReq, FormReq } from '../../proxy/proxy';

interface MediaInputPreviewProps {
  currentFieldState: FormQuestionMediaReq;
}

const MediaInputPreview: React.FC<MediaInputPreviewProps> = ({ currentFieldState }) => {
  const [fieldState, setFieldState] = useState<FormQuestionMediaReq>(currentFieldState);

  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  const handleFileChange = ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
    if (files && (files.length < 1 || files.length > 5)) {
      alert('Please select between 1 and 5 files.');
    } else {
      // Handle the file input
    }
  };

  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <Typography variant="h4" gutterBottom>
          {fieldState.title}
        </Typography>
        <input
          type="file"
          multiple
          name={fieldState.title}
          placeholder={fieldState.subTitle ?? ''}
          required={fieldState.required}
          onChange={handleFileChange}
          style={{
            display: 'block',
            width: '100%',
            border: '1px solid #ccc',
            borderRadius: 4,
            padding: '8px',
            boxSizing: 'border-box',
          }}
        />
        <p style={{ margin: '10px 0' }}>Upload between 1 and 5 files.</p>
      </Grid>
    </Fragment>
  );
};

export default MediaInputPreview;
