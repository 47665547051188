import React, { Fragment, useRef, useState } from 'react';
import {
  Box,
  Button,
  Tooltip,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
  Container,
  Card,
  CardHeader,
  IconButton,
  Divider,
  CardContent,
  CardActions,
  MenuItem,
  ListItemText,
  Paper,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import BackIcon from '@mui/icons-material/ChevronLeft';
import {
  showAlert as showGlobalAlert,
  clearAllAlerts as clearAllGlobalAlerts
} from '../store/actions/alertActions';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { FormQuestionMediaReq, FormQuestionMultiChoiceReq, FormQuestionNumericReq, FormQuestionSignatureReq, FormQuestionSingleChoiceReq, FormQuestionTextualReq, FormReq } from '../proxy/proxy';
import HeaderPreview from '../components/formpreview/HeaderPreview';
import TextFieldPreview from '../components/formpreview/TextFieldPreview';
import NumberFieldPreview from '../components/formpreview/NumberFieldPreview';
import MultiChoiceInputPreview from '../components/formpreview/MultiChoicePreview';
import SingleChoiceInputPreview from '../components/formpreview/SingleChoicePreview';
import MediaInputPreview from '../components/formpreview/MediaInputPreview';
import SignatureInputPreview from '../components/formpreview/SignatureInputPreview';

interface Props {
  currentFormState: FormReq;
  currentFieldStates: any;
  showAlert: (message: any) => any;
  clearAllAlerts: () => any;
  showBusyIndicator: () => any;
  hideBusyIndicator: () => any;
}

const FormPreview: React.FC<Props> = ({
  currentFormState,
  currentFieldStates,
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  type FormQuestionTypes = FormQuestionTextualReq | FormQuestionNumericReq | FormQuestionMultiChoiceReq | FormQuestionSingleChoiceReq | FormQuestionMediaReq | FormQuestionSignatureReq;
  // const [fieldStates, setFieldStates] = useState<FormQuestionTypes[]>(currentFieldStates);

  const [formState, setFormState] = useState<FormReq>(currentFormState);

  const renderElements = (item : any, index: number) => {
    switch (item.questionType) {
      case 0:
        return (
          <TextFieldPreview
            currentFieldState={item}
          />
        );
      case 1:
        return (
          <NumberFieldPreview
            currentFieldState={item}
          />
        );
      case 2:
        return (
          <MultiChoiceInputPreview
            currentFieldState={item}
          />
        );
      case 3:
        return (
          <SingleChoiceInputPreview
            currentFieldState={item}
          />
        );
      case 6:
        return (
          <MediaInputPreview
            currentFieldState={item}
          />
        );
      case 7:
        return (
          <SignatureInputPreview
            currentFieldState={item}
          />
        );
      default:
        return <Fragment />;
    }
  };

  return (
    <>
      <Helmet>
        <title>Question Details</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Grid container spacing={1} direction="row" justifyContent="center">
          <Grid item md={6}>
            <HeaderPreview
              key={formState.name}
              currentFormState={formState}
            />
            <Card>
              <Divider />
              <CardContent>
                <form>
                  <CardContent>
                    <Grid container spacing={6}>
                      {currentFieldStates.map((item: FormQuestionTypes, index: number) => (
                        <React.Fragment key={index}>
                          <Grid item xs={1}>
                            <Typography variant="h4">
                              {index + 1}. {/* Adding the index number */}
                            </Typography>
                          </Grid>
                          <Grid item xs={11}>
                            {renderElements(item, item.order ?? 0)}
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </CardContent>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(FormPreview);
