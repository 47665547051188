import React, { useState, Fragment, useEffect, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import {
  Box,
  Grid,
  Paper,
  TextField,
  Button,
  IconButton,
  Link,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { FormQuestionSignatureReq } from '../../proxy/proxy';

interface SignatureInputPreviewProps {
  currentFieldState: FormQuestionSignatureReq;
}

const SignatureInputPreview: React.FC<SignatureInputPreviewProps> = ({
  currentFieldState
}) => {
  const [fieldState, setFieldState] = useState<FormQuestionSignatureReq>(currentFieldState);

  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const signatureRef = useRef<SignatureCanvas>(null);

  const handleBeginSigning = () => {
    setShowPlaceholder(false);
  };

  const clearSignature = () => {
    if (signatureRef.current) {
      signatureRef.current.clear();
      setShowPlaceholder(true);
    }
  };

  const saveSignature = () => {
    if (signatureRef.current) {
      const dataURL = signatureRef.current.toDataURL();
    }
  };

  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <Typography variant="h4" gutterBottom>
          {fieldState.title}
        </Typography>
        <Box
          sx={{
            border: '1px solid #ccc',
            borderRadius: 1,
            width: 500,
            height: 200,
            position: 'relative',
            backgroundColor: 'white',
            overflow: 'hidden'
          }}
        >
          {showPlaceholder && (
            <img
              src="https://www.vhv.rs/dpng/d/586-5864567_fake-signature-png-transparent-png.png" // Replace this URL with your actual signature image URL
              alt="Signature Placeholder"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                position: 'absolute',
                opacity: 0.1,
                top: 0,
                left: 0,
                zIndex: 1 // Ensures the image is behind the canvas
              }}
            />
          )}
          <SignatureCanvas
            ref={signatureRef}
            penColor="black"
            canvasProps={{
              width: 500,
              height: 200,
              className: 'sigCanvas',
              style: {
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 2 // Ensure canvas is above the placeholder
              }
            }}
            backgroundColor="transparent"
            onBegin={handleBeginSigning} // Hide placeholder when signing begins
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Button variant="outlined" color="secondary" onClick={clearSignature}>
            Clear Signature
          </Button>
        </Box>
        {/* <Typography variant="subtitle1" gutterBottom>
          {fieldState.subTitle}
        </Typography> */}
      </Grid>
    </Fragment>
  );
};

export default SignatureInputPreview;
