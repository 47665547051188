import React, { useState, Fragment, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  TextField,
  Button,
  IconButton,
  Link,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { FormReq } from '../../proxy/proxy';

interface HeaderPreviewProps {
  currentFormState: FormReq;
}

const HeaderPreview: React.FC<HeaderPreviewProps> = ({ currentFormState }) => {
  const [formState, setFormState] = useState<FormReq>(currentFormState);

  return (
    <Fragment>
      <Box sx={{ mb: 3 }}>
        <Paper elevation={2} sx={{ p: 3, borderTop: '8px solid #5664d2' }}>
          <Typography variant="h1" component="h2">
            {formState.name}
          </Typography>
          <Typography mt={2} variant="body1" component="h6">
            {formState.description}
          </Typography>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableCell>Serial No</TableCell> */}
                  <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Title</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Link</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(formState?.formLinks ?? []).map((link, index) => (
                  <TableRow key={index}>
                    {/* <TableCell>{index + 1}</TableCell> */}
                    <TableCell>{link.title}</TableCell>
                    <TableCell>
                      <Link
                        href={link.link ?? ''}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          textDecoration: 'none',
                          color: '#5664d2',
                          '&:hover': {
                            textDecoration: 'underline'
                          }
                        }}
                      >
                        {link.link}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Fragment>
  );
};

export default HeaderPreview;
