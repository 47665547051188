import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Hidden, Container, Box, Card, CardHeader, Divider, CardContent, IconButton, Toolbar } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import { connect } from 'react-redux';
import {
  CheckSquare as CheckSquareIcon,
  Edit as EditIcon
} from 'react-feather';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '../components/Table';
import MobileDataList from '../components/MobileDataList';
import GlobalSearch from '../components/GlobalSearch';
import AppConstants from '../constants/AppConstants';
import { refreshData, reset, searchByText, changePage, changeSortModel } from '../store/actions/FormActions';
import { RootState } from '../store/store';
import ConfirmButton from '../components/ConfirmButton';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import { formatErrorMessage, formatSuccessMessage } from '../utils/common';
import PreCheckQuestionsService from '../services/PreCheckQuestionsService';
import { FormRes, PreCheckQuestionRes } from '../proxy/proxy';
import FormsService from '../services/FormsService';

interface Props {
  visibleRows?: Array<PreCheckQuestionRes>,
  totalRowCount?: number,
  searchedText?: string,
  currentPage?: number,
  sortModel?: any,
  fetchAllRows: any,
  resetPage: any,
  filterByText?: any,
  onPageChange?: any,
  onSort?: (model: any) => any,

  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
}

const Forms: React.FC<Props> = ({
  visibleRows = [],
  totalRowCount = 0,
  searchedText,
  currentPage = 0,
  sortModel,

  fetchAllRows,
  resetPage,
  filterByText,
  onPageChange,
  onSort = () => null,

  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator,
}) => {
  const navigate = useNavigate();

  const onRowClick = ({ id }: any) => {
    navigate(`/app/forms/builder/${id}`);
  };

  const handleDeleteClick = (questionId: number) => {
    showBusyIndicator();
    clearAllAlerts();
    FormsService.deleteForm(questionId)
      .then(() => {
        showAlert(formatSuccessMessage('Form deleted successfully'));
        fetchAllRows();
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1
    },
    {
      field: 'onOpenMessage',
      headerName: 'On Open Message',
      flex: 1
    },
    {
      field: 'onCompleteMessage',
      headerName: 'On Complete Message',
      flex: 1
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'boolean',
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        const { row } = params;
        return (
          <>
            <ConfirmButton
              buttonIcon={<DeleteIcon />}
              isIconButton
              dialogTitle="Are you sure?"
              showCancelButton
              message="Form will be deleted permanently."
              onConfirm={() => handleDeleteClick(row.id)}
              buttonColor="secondary"
            />
            <IconButton size="medium" aria-label="edit" onClick={() => onRowClick(row)}>
              <EditIcon />
            </IconButton>
          </>
        );
      },
      renderHeader: () => (<span />)
    }
  ];

  const onSearchTextChange = (event: any) => {
    filterByText(event?.target?.value);
  };

  const refresh = () => {
    resetPage();
    fetchAllRows();
  };

  useEffect(() => {
    fetchAllRows();
  }, []);

  const addQuestion = () => {
    navigate('/app/forms/builder/add');
  };

  const renderEachMobileCard = (row: FormRes) => (
    <Card key={row.id} sx={{ marginBottom: '10px' }} variant="outlined">
      <CardHeader
        avatar={<CheckSquareIcon />}
        action={(
          <>
            <ConfirmButton
              buttonIcon={<DeleteIcon />}
              isIconButton
              dialogTitle="Are you sure?"
              showCancelButton
              message="Question will be deleted permanently."
              onConfirm={() => handleDeleteClick(row.id ?? 0)}
              buttonColor="warning"
            />
            <IconButton aria-label="edit" onClick={() => onRowClick(row)}>
              <EditIcon />
            </IconButton>
          </>
        )}
        title={row.name} // Displaying the name field
        subheader={(
          <>
            <div>{`Description: ${row.description}`}</div>
            <div>{`On Open Message: ${row.onOpenMessage}`}</div>
            <div>{`On Complete Message: ${row.onCompleteMessage}`}</div>
          </>
        )}
      />
    </Card>
  );

  return (
    <>
      <Helmet>
        <title>All Forms | {AppConstants.APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="xl">
          <Card>
            <CardHeader
              action={
                (
                  <>
                    <IconButton onClick={refresh}>
                      <RefreshIcon />
                    </IconButton>
                    <IconButton onClick={addQuestion}>
                      <AddIcon />
                    </IconButton>
                  </>
                )
              }
              title="All Forms"
            />
            <Divider />
            <CardContent>
              <Toolbar style={{ justifyContent: 'flex-end' }}>
                <GlobalSearch searchedText={searchedText} onSearchTextChange={onSearchTextChange} width="300px" />
              </Toolbar>
              <Hidden mdDown>
                <Table
                  rows={visibleRows}
                  columns={columns}
                  onRowDoubleClick={onRowClick}
                  totalRows={totalRowCount}
                  page={currentPage}
                  onPageChange={onPageChange}
                  sortModel={sortModel}
                  onSortModelChange={onSort}
                />
              </Hidden>
              <Hidden mdUp>
                <MobileDataList
                  rows={visibleRows}
                  renderEachRow={renderEachMobileCard}
                  totalRows={totalRowCount}
                  page={currentPage}
                  pageSize={8}
                  onPageChange={onPageChange}
                />
              </Hidden>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  visibleRows: state.forms?.filteredRows,
  totalRowCount: state.forms?.allRows?.length,
  currentPage: state.forms?.currentPage,
  searchedText: state.forms?.searchedText,
  sortModel: state.forms?.sortModel,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchAllRows: () => dispatch(refreshData()),
  resetPage: () => dispatch(reset()),
  filterByText: (text: string) => dispatch(searchByText(text)),
  onPageChange: (page: number) => dispatch(changePage(page)),
  onSort: (sortModel: any) => dispatch(changeSortModel(sortModel)),

  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(Forms);
