import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  IconButton,
  Typography,
  MenuItem,
  ListItemText,
  Button
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { FormQuestionMultiChoiceReq, FormQuestionTextualReq, RegularExpressionRes } from '../../proxy/proxy';
import { showAlert } from '../../store/actions/alertActions';
import { formatErrorMessage } from '../../utils/common';
import FormsService from '../../services/FormsService';

interface MultiChoiceInputProps {
  order: number;
  currentFieldState: FormQuestionMultiChoiceReq;
  fieldStateChanged: (fieldState: any, order: number) => void;
  deleteEl: (order: number) => void;
  handleArrowUpClick: (order: number) => void;
  handleArrowDownClick: (order: number) => void;
  duplicateElement: (order: number) => void;
}

const MultiChoiceInput: React.FC<MultiChoiceInputProps> = ({
  order,
  currentFieldState,
  fieldStateChanged,
  deleteEl,
  duplicateElement,
  handleArrowDownClick,
  handleArrowUpClick
}) => {
  const [fieldState, setFieldState] = useState<FormQuestionMultiChoiceReq>({
    ...currentFieldState,
    choices: currentFieldState.choices || [] // Initialize choices if not present
  });
  const [regularExpressions, setRegularExpressions] = useState<RegularExpressionRes[]>([]);

  const refreshData = () => {
    const promises: Array<Promise<any>> = [
      FormsService.getAllRegularExpressions(),
    ];

    Promise.all(promises)
      .then((result) => {
        setRegularExpressions(result[0]);
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      });
  };

  useEffect(() => {
    fieldStateChanged(fieldState, order);
  }, [fieldState]);

  useEffect(() => {
    refreshData();
  }, []);

  const addChoice = () => {
    setFieldState({
      ...fieldState,
      choices: [...(fieldState.choices ?? []), ''] // Add an empty string as a new choice
    });
  };

  const handleChoiceChange = (index: number, value: string) => {
    const updatedChoices = (fieldState.choices ?? []).map((choice, i) =>
      (i === index ? value : choice));
    setFieldState({ ...fieldState, choices: updatedChoices });
  };

  const removeChoice = (index: number) => {
    const updatedChoices = (fieldState.choices ?? []).filter((_, i) => i !== index);
    setFieldState({ ...fieldState, choices: updatedChoices });
  };

  return (
    <Fragment>
      <Paper elevation={2}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px 20px'
          }}
        >
          <Typography color="textPrimary" variant="h4">
            Multiple Choice Question
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              control={(
                <Switch
                  checked={fieldState.required || false}
                  onChange={() =>
                    setFieldState({ ...fieldState, required: !fieldState.required })
                  }
                  name="required-field"
                  color="secondary"
                />
              )}
              label="Required"
              sx={{ ml: 2 }}
            />
            <IconButton onClick={() => handleArrowUpClick(order)}>
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton onClick={() => handleArrowDownClick(order)}>
              <ArrowDownwardIcon />
            </IconButton>
            <IconButton onClick={() => deleteEl(order)}>
              <DeleteIcon color="primary" />
            </IconButton>
            <IconButton onClick={() => duplicateElement(order)}>
              <ContentCopyIcon color="primary" />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ p: 3, mb: 4 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                value={fieldState.title}
                variant="filled"
                label="Question Title"
                onChange={(e) => setFieldState({ ...fieldState, title: e.target.value })}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={fieldState.subTitle}
                variant="filled"
                label="Question Subtitle"
                onChange={(e) => setFieldState({ ...fieldState, subTitle: e.target.value })}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>

            <Grid item xs={12}>
              {(fieldState?.choices || []).map((choice, index) => (
                <Grid container key={index} spacing={2} alignItems="center" sx={{ mb: 2 }}>
                  <Grid item xs={10}>
                    <TextField
                      variant="filled"
                      label={`Choice ${index + 1}`}
                      value={choice}
                      onChange={(e) => handleChoiceChange(index, e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton onClick={() => removeChoice(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>

            <Grid item xs={4}>
              <Button variant="contained" onClick={addChoice} fullWidth>
                Add Choice
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Fragment>
  );
};

export default MultiChoiceInput;
