import { AxiosResponse } from 'axios';
import FormattedVehicle from '../models/FormattedVehicle';
import AppConstants from '../constants/AppConstants';
import EnumService from './EnumService';
import { Client, FormQuestionTextualReq, FormReq, VehicleReq, VehicleRes, VehicleResPage } from '../proxy/proxy';
import configureAxios from '../proxy/ConfigureAxios';

// const api = new Client(AppConstants.BASE_API_URL, configureAxios());

const FormsService = {
  createForm: (data: FormReq) => configureAxios().create3(data),
  addQuestion: (id: number, data: FormQuestionTextualReq) => configureAxios().addQuestion(id, data),
  getFormById: (id: number) =>
    configureAxios().get5(id).then((resp) => resp),
  getAllForms: () =>
    configureAxios().allPOST().then((resp) => resp),
  allFormsByRole: () =>
    configureAxios().allByRole().then((resp) => resp),
  getAllRegularExpressions: () =>
    configureAxios().allGET3().then((resp) => resp),
  deleteForm: (id: number = 0) => configureAxios().softDelete(id),
  setIsActive: (id: number = 0, isActive: boolean) => configureAxios().setIsActive(id, isActive)
};

export default FormsService;
