import React, { useState, Fragment, useEffect } from 'react';
import {
  Grid,
  Typography,
  TextField
} from '@mui/material';
import { FormQuestionNumericReq } from '../../proxy/proxy';

interface NumberFieldPreviewProps {
  currentFieldState: FormQuestionNumericReq;
}

const NumberFieldPreview: React.FC<NumberFieldPreviewProps> = ({ currentFieldState }) => {
  const [fieldState, setFieldState] = useState<FormQuestionNumericReq>(currentFieldState);
  const [inputValue, setInputValue] = useState<string>('');
  const [error, setError] = useState<string>('');

  const validateInput = (value: string) => {
    const numericValue = parseFloat(value);

    switch (fieldState.formQuestionNumericRestrictionType) {
      case 1: // GreaterThan
        if (numericValue <= (fieldState.restrictionInput1 ?? 0)) {
          setError(`Value must be greater than ${fieldState.restrictionInput1}`);
        } else {
          setError('');
        }
        break;
      case 2: // GreaterThanOrEqualTo
        if (numericValue < (fieldState.restrictionInput1 ?? 0)) {
          setError(`Value must be greater than or equal to ${fieldState.restrictionInput1}`);
        } else {
          setError('');
        }
        break;
      case 3: // LessThan
        if (numericValue >= (fieldState.restrictionInput1 ?? 0)) {
          setError(`Value must be less than ${fieldState.restrictionInput1}`);
        } else {
          setError('');
        }
        break;
      case 4: // LessThanOrEqualTo
        if (numericValue > (fieldState.restrictionInput1 ?? 0)) {
          setError(`Value must be less than or equal to ${fieldState.restrictionInput1}`);
        } else {
          setError('');
        }
        break;
      case 5: // EqualTo
        if (numericValue !== (fieldState.restrictionInput1 ?? 0)) {
          setError(`Value must be equal to ${fieldState.restrictionInput1}`);
        } else {
          setError('');
        }
        break;
      case 6: // NotEqualTo
        if (numericValue === (fieldState.restrictionInput1 ?? 0)) {
          setError(`Value must not be equal to ${fieldState.restrictionInput1}`);
        } else {
          setError('');
        }
        break;
      case 7: // Between
        if (numericValue < (fieldState.restrictionInput1 ?? 0) || numericValue > (fieldState.restrictionInput2 ?? 0)) {
          setError(`Value must be between ${fieldState.restrictionInput1} and ${fieldState.restrictionInput2}`);
        } else {
          setError('');
        }
        break;
      case 8: // NotBetween
        if (numericValue >= (fieldState.restrictionInput1 ?? 0) && numericValue <= (fieldState.restrictionInput2 ?? 0)) {
          setError(`Value must not be between ${fieldState.restrictionInput1} and ${fieldState.restrictionInput2}`);
        } else {
          setError('');
        }
        break;
      default:
        setError('');
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);
    validateInput(value);
  };

  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <Typography variant="h4" gutterBottom>
          {fieldState.title}
        </Typography>
        <TextField
          fullWidth
          name={fieldState.title}
          placeholder={fieldState.subTitle ?? ''}
          variant="outlined"
          type="number"
          InputLabelProps={{ shrink: true }}
          required={fieldState.required}
          value={inputValue}
          onChange={handleChange}
          error={!!error}
          helperText={error}
        />
      </Grid>
    </Fragment>
  );
};

export default NumberFieldPreview;
