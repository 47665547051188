import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import { Box, Button, ClickAwayListener, Grid, IconButton, ListItemText, Menu, MenuItem, Tab, Tabs, TextField, Tooltip } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import DialpadIcon from '@mui/icons-material/Dialpad';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import StarIcon from '@mui/icons-material/Star';
import SortIcon from '@mui/icons-material/Sort';
import ImageIcon from '@mui/icons-material/Image';
import CreateIcon from '@mui/icons-material/Create';
import EditIcon from '@mui/icons-material/Edit';
import AssignmentIcon from '@mui/icons-material/Assignment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import { hide, show } from '../store/actions/busyIndicatorActions';
import Header from '../components/formbuilder/Header';
import AppConstants from '../constants/AppConstants';
import { EnumRes, FormQuestionMediaReq, FormQuestionMultiChoiceReq, FormQuestionNumericReq, FormQuestionSignatureReq, FormQuestionSingleChoiceReq, FormQuestionTextualReq, FormQuestionType, FormReq, FormRes, RegularExpressionRes } from '../proxy/proxy';
import FormsService from '../services/FormsService';
import { formatErrorMessage, formatSuccessMessage, sortByKey } from '../utils/common';
import EnumService from '../services/EnumService';
import FormPreview from './FormPreview';
import TextFieldInput from '../components/formbuilder/TextField';
import NumberInput from '../components/formbuilder/NumberInput';
import MultiChoiceInput from '../components/formbuilder/MultiChoiceInput';
import SingleChoiceInput from '../components/formbuilder/SingleChoiceInput';
import MediaInput from '../components/formbuilder/MediaInput';
import SignatureInput from '../components/formbuilder/SignatureInput';

interface CommonInputProps {
  order: number,
  fieldStateChanged: (fieldState: any, order: number) => void;
  handleArrowDownClick: (order: number) => void;
  handleArrowUpClick: (order: number) => void;
  deleteEl: (order: number) => void;
  duplicateElement: (order: number) => void;
}

interface FormElement {
  id: number;
  questionType: number;
  pageNumber: number;
  title: string;
  subtitle?: string;
  regularExpression?: string;
  required: boolean;
  formQuestionNumericRestrictionType?: number;
  restrictionInput1?: number;
  restrictionInput2?: number;
  choices?: string[];
  minimumFilesLimit?: number;
  maximumFilesLimit?: number;
  isMultiline?: boolean;
  // options?: Option[];
  // value: any;
}

interface Props {
  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
}

const FormSubmission: React.FC<Props> = ({
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const { formId } = useParams();
  const existingFormId = !Number.isNaN(Number(formId)) ? Number(formId) : 0;

  const [formState, setFormState] = useState<FormReq>({
    name: '',
    description: '',
    onOpenMessage: '',
    onCompleteMessage: '',
    formLinks: [],
    appRoles: [],
  });

  type FormQuestionTypes = FormQuestionTextualReq | FormQuestionNumericReq | FormQuestionMultiChoiceReq | FormQuestionSingleChoiceReq | FormQuestionMediaReq | FormQuestionSignatureReq;
  const [fieldStates, setFieldStates] = useState<FormQuestionTypes[]>([]);

  const refreshData = () => {
    if (!Number.isNaN(existingFormId) && existingFormId > 0) {
      FormsService.getFormById(Number(existingFormId))
        .then((result) => {
          const receivedForm: FormRes = result;
          setFormState((prevState) => ({
            ...prevState,
            name: receivedForm.name ?? '',
            description: receivedForm.description ?? '',
            onOpenMessage: receivedForm.onOpenMessage ?? '',
            onCompleteMessage: receivedForm.onCompleteMessage ?? '',
            formLinks: receivedForm.formLinks ?? [],
            appRoles: (receivedForm.formToAppRoleMap ?? []).map((item) => item.appRole?.name),
            formType: receivedForm.formType ?? null,
          }));
          setFieldStates(receivedForm?.formQuestions?.map((element) => JSON.parse(JSON.stringify(element))) || []);
        }).catch((err) => {
          showAlert(formatErrorMessage(JSON.parse(err.response)));
        })
        .then(() => {
          hideBusyIndicator();
        });
    }

    showBusyIndicator();
  };

  useEffect(() => {
    clearAllAlerts();
    refreshData();
  }, []);

  return (
    <Fragment>
      <FormPreview
        key={formState.formType}
        currentFormState={formState}
        currentFieldStates={fieldStates}
      />
    </Fragment>
  );
};

interface TabPanelProps {
  children?: ReactNode;
  value: number;
  index: number;
}

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(FormSubmission);
