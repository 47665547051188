import React, { useState, Fragment } from 'react';
import {
  Grid,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from '@mui/material';
import { FormQuestionMultiChoiceReq } from '../../proxy/proxy';

interface MultiChoiceInputPreviewProps {
  currentFieldState: FormQuestionMultiChoiceReq;
}

const MultiChoiceInputPreview: React.FC<MultiChoiceInputPreviewProps> = ({ currentFieldState }) => {
  const [fieldState, setFieldState] = useState<FormQuestionMultiChoiceReq>(currentFieldState);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [error, setError] = useState('');

  const validateInput = (values: string[]) => {
    if (fieldState.required && values.length === 0) {
      setError('At least one option must be selected');
    } else {
      setError('');
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    const updatedValues = checked
      ? [...selectedValues, value]
      : selectedValues.filter((v) => v !== value);

    setSelectedValues(updatedValues);
    validateInput(updatedValues);
  };

  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <Typography variant="h4" gutterBottom>
          {fieldState.title}
        </Typography>
        <FormControl component="fieldset" error={!!error} required={fieldState.required}>
          <FormGroup>
            {fieldState.choices?.map((choice, index) => (
              <FormControlLabel
                key={index}
                control={(
                  <Checkbox
                    checked={selectedValues.includes(choice)}
                    onChange={handleChange}
                    value={choice}
                  />
                )}
                label={choice}
              />
            ))}
          </FormGroup>
          {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      </Grid>
    </Fragment>
  );
};

export default MultiChoiceInputPreview;
