import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import { Box, Button, ClickAwayListener, Grid, IconButton, ListItemText, Menu, MenuItem, Tab, Tabs, TextField, Tooltip } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import DialpadIcon from '@mui/icons-material/Dialpad';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import StarIcon from '@mui/icons-material/Star';
import SortIcon from '@mui/icons-material/Sort';
import ImageIcon from '@mui/icons-material/Image';
import CreateIcon from '@mui/icons-material/Create';
import EditIcon from '@mui/icons-material/Edit';
import AssignmentIcon from '@mui/icons-material/Assignment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import { hide, show } from '../store/actions/busyIndicatorActions';
import Header from '../components/formbuilder/Header';
import AppConstants from '../constants/AppConstants';
import { EnumRes, FormQuestionMediaReq, FormQuestionMultiChoiceReq, FormQuestionNumericReq, FormQuestionSignatureReq, FormQuestionSingleChoiceReq, FormQuestionTextualReq, FormQuestionType, FormReq, FormRes, RegularExpressionRes } from '../proxy/proxy';
import FormsService from '../services/FormsService';
import { formatErrorMessage, formatSuccessMessage, sortByKey } from '../utils/common';
import EnumService from '../services/EnumService';
import FormPreview from './FormPreview';
import TextFieldInput from '../components/formbuilder/TextField';
import NumberInput from '../components/formbuilder/NumberInput';
import MultiChoiceInput from '../components/formbuilder/MultiChoiceInput';
import SingleChoiceInput from '../components/formbuilder/SingleChoiceInput';
import MediaInput from '../components/formbuilder/MediaInput';
import SignatureInput from '../components/formbuilder/SignatureInput';

interface CommonInputProps {
  order: number,
  fieldStateChanged: (fieldState: any, order: number) => void;
  handleArrowDownClick: (order: number) => void;
  handleArrowUpClick: (order: number) => void;
  deleteEl: (order: number) => void;
  duplicateElement: (order: number) => void;
}

interface FormElement {
  id: number;
  questionType: number;
  pageNumber: number;
  title: string;
  subtitle?: string;
  regularExpression?: string;
  required: boolean;
  formQuestionNumericRestrictionType?: number;
  restrictionInput1?: number;
  restrictionInput2?: number;
  choices?: string[];
  minimumFilesLimit?: number;
  maximumFilesLimit?: number;
  isMultiline?: boolean;
  // options?: Option[];
  // value: any;
}

interface Props {
  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
}

const FormBuilder: React.FC<Props> = ({
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const initVal = AppConstants.formEl[0]?.value;

  const { formId } = useParams();
  const existingFormId = !Number.isNaN(Number(formId)) ? Number(formId) : 0;

  const [formState, setFormState] = useState<FormReq>({
    name: '',
    description: '',
    onOpenMessage: '',
    onCompleteMessage: '',
    formLinks: [],
    appRoles: [],
  });

  type FormQuestionTypes = FormQuestionTextualReq | FormQuestionNumericReq | FormQuestionMultiChoiceReq | FormQuestionSingleChoiceReq | FormQuestionMediaReq | FormQuestionSignatureReq;
  const [fieldStates, setFieldStates] = useState<FormQuestionTypes[]>([]);
  const [formTypes, setFormTypes] = useState<Array<EnumRes>>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isActive, setIsActive] = useState<boolean>(false);

  const items = fieldStates;

  const open = Boolean(anchorEl);

  const refreshData = () => {
    const promises: Array<Promise<any>> = [
      EnumService.getAllEnums(),
    ];

    if (!Number.isNaN(existingFormId) && existingFormId > 0) {
      FormsService.getFormById(Number(existingFormId))
        .then((result) => {
          const receivedForm: FormRes = result;
          setFormState((prevState) => ({
            ...prevState,
            name: receivedForm.name ?? '',
            description: receivedForm.description ?? '',
            onOpenMessage: receivedForm.onOpenMessage ?? '',
            onCompleteMessage: receivedForm.onCompleteMessage ?? '',
            formLinks: receivedForm.formLinks ?? [],
            appRoles: (receivedForm.formToAppRoleMap ?? []).map((item) => item.appRole?.name),
            formType: receivedForm.formType ?? null,
          }));
          setFieldStates(receivedForm?.formQuestions?.map((element) => JSON.parse(JSON.stringify(element))) || []);
          setIsActive(receivedForm?.isActive ?? false);
        }).catch((err) => {
          showAlert(formatErrorMessage(JSON.parse(err.response)));
        })
        .then(() => {
          hideBusyIndicator();
        });
    }

    Promise.all(promises)
      .then((result) => {
        setFormTypes(result[0].formQuestionTypes.filter((x: any) => x.id !== 4 && x.id !== 5));
      }).catch((err) => {
        showAlert(formatErrorMessage(JSON.parse(err.response)));
      })
      .then(() => {
        hideBusyIndicator();
      });

    showBusyIndicator();
  };

  useEffect(() => {
    clearAllAlerts();
    refreshData();
  }, []);

  const addElement = (questionType: number) => {
    const newElement: FormQuestionTypes = {
      discriminator: questionType,
      order: fieldStates.length,
      questionType,
      title: '',
      subTitle: '',
      pageNumber: 1,
      required: false,
    };

    setFieldStates((prevState) => [...prevState, newElement]);
  };

  const deleteEl = (id: number) => {
    setFieldStates((prevState) => prevState.filter((val) => val.order !== id));
  };

  const addAfter = (elArray: FormQuestionTypes[], index: number, newEl: FormQuestionTypes): FormQuestionTypes[] => [
    ...elArray.slice(0, index + 1),
    newEl,
    ...elArray.slice(index + 1)
  ];

  const duplicateElement = (index: number) => {
    const el = items[index];
    const newEl = {
      ...el,
      id: Math.random() * 100, // or any logic for new ID
    };
    const newArr = addAfter(items, index, newEl);
    setFieldStates(newArr);
  };

  const handleFieldStateChanged = (fieldState: any, order: number) => {
    if (order === -1 || order === undefined) return;

    console.log(fieldState);
    const newItems = [...items];
    newItems[order] = fieldState;
    setFieldStates(newItems);
  };

  const handleArrowUpClick = (id: number) => {
    // Find the index of the item with the given id
    const index = items.findIndex((item) => item.order === id);

    // If the item is already at the first position or not found, do nothing
    if (index <= 0) {
      return;
    }

    // Create a new array with the item moved up
    const newItems = [...items];
    const temp = newItems[index - 1];
    newItems[index - 1] = newItems[index];
    newItems[index] = temp;
    console.log(newItems);
    // Update the state with the new array
    setFieldStates(newItems);
  };

  const handleArrowDownClick = (id: number) => {
    // Find the index of the item with the given id
    const index = items.findIndex((item) => item.order === id);

    // If the item is already at the last position or not found, do nothing
    if (index === -1 || index === items.length - 1) {
      return;
    }

    // Create a new array with the item moved down
    const newItems = [...items];
    const temp = newItems[index + 1];
    newItems[index + 1] = newItems[index];
    newItems[index] = temp;

    // Update the state with the new array
    setFieldStates(newItems);
  };

  const submitQuestions = (newFormId: number) => {
    items.forEach((element, index) => {
      const body = {
        __discriminator: formTypes.find((x) => x.id === element.questionType)?.value ?? '',
        discriminator: element.questionType,
        order: index,
        questionType: element.questionType,
        title: element.title,
        subTitle: element.subTitle,
        pageNumber: element.pageNumber,
        required: element.required,
        ...(element.questionType === 0 && 'isMultiline' in element && { isMultiline: element.isMultiline }),
        ...(element.questionType === 0 && 'regularExpression' in element && { regularExpression: element.regularExpression }),
        ...(element.questionType === 1 && 'formQuestionNumericRestrictionType' in element && { formQuestionNumericRestrictionType: element.formQuestionNumericRestrictionType }),
        ...(element.questionType === 1 && 'restrictionInput1' in element && { restrictionInput1: element.restrictionInput1 }),
        ...(element.questionType === 1 && 'restrictionInput2' in element && { restrictionInput2: element.restrictionInput2 }),
        ...(element.questionType === 2 && 'choices' in element && { choices: element.choices }),
        ...(element.questionType === 3 && 'choices' in element && { choices: element.choices }),
        ...(element.questionType === 6 && 'minimumFilesLimit' in element && { minimumFilesLimit: element.minimumFilesLimit }),
        ...(element.questionType === 6 && 'maximumFilesLimit' in element && { maximumFilesLimit: element.maximumFilesLimit }),
      };

      console.log(body);

      const promise = FormsService.addQuestion(newFormId, body);

      promise
        .then((response: any) => {
          showAlert(formatSuccessMessage('Form question added successfully.'));
        }).catch((err) => {
          showAlert(formatErrorMessage(err?.response));
        }).then(() => {
          hideBusyIndicator();
        });
    });
  };

  const handleSubmit = () => {
    const body: FormReq = {
      name: formState.name,
      description: formState.description,
      onOpenMessage: formState.onOpenMessage,
      onCompleteMessage: formState.onCompleteMessage,
      formLinks: formState.formLinks,
      appRoles: formState.appRoles,
      formType: formState.formType
    };
    // submitQuestions(0);
    const promise = FormsService.createForm(body);

    promise
      .then((response: any) => {
        showAlert(formatSuccessMessage('Form added successfully.'));
        submitQuestions(response.id);
      }).catch((err) => {
        showAlert(formatErrorMessage(err?.response));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const handleActive = (statusIsActive: boolean) => {
    const promise = FormsService.setIsActive(existingFormId, statusIsActive);

    promise
      .then((response: any) => {
        showAlert(formatSuccessMessage('Form status updated successfully'));
      }).catch((err) => {
        showAlert(formatErrorMessage(err?.response));
      }).then(() => {
        hideBusyIndicator();
      });
  };

  const renderElements = (item : any, index: number) => {
    const commonProps: CommonInputProps = {
      order: index,
      fieldStateChanged: (fieldState: any, order: number) => handleFieldStateChanged(fieldState, order),
      handleArrowDownClick: (order: number) => handleArrowDownClick(order),
      handleArrowUpClick: (order: number) => handleArrowUpClick(order),
      deleteEl: (order: number) => deleteEl(order),
      duplicateElement: (order: number) => duplicateElement(order),
    };

    switch (item.questionType) {
      case 0:
        return (
          <TextFieldInput
            {...commonProps}
            currentFieldState={item}
          />
        );
      case 1:
        return (
          <NumberInput
            {...commonProps}
            currentFieldState={item}
          />
        );
      case 2:
        return (
          <MultiChoiceInput
            {...commonProps}
            currentFieldState={item}
          />
        );
      case 3:
        return (
          <SingleChoiceInput
            {...commonProps}
            currentFieldState={item}
          />
        );
      case 6:
        return (
          <MediaInput
            {...commonProps}
            currentFieldState={item}
          />
        );
      case 7:
        return (
          <SignatureInput
            {...commonProps}
            currentFieldState={item}
          />
        );
      default:
        return <Fragment />;
    }
  };

  const getIcon = (value: any) => {
    switch (value) {
      case 0:
        return <TextFieldsIcon fontSize="small" />;
      case 1:
        return <DialpadIcon fontSize="small" />;
      case 2:
        return <CheckBoxIcon fontSize="small" />;
      case 3:
        return <RadioButtonCheckedIcon fontSize="small" />;
      case 4:
        return <StarIcon fontSize="small" />;
      case 5:
        return <SortIcon fontSize="small" />;
      case 6:
        return <ImageIcon fontSize="small" />;
      case 7:
        return <CreateIcon fontSize="small" />;
      default:
        return null;
    }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (formType: EnumRes) => {
    addElement(formType.id ?? 0);
    setAnchorEl(null);
  };

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: any, newValue: any) => {
    console.log(newValue);
    setActiveTab(newValue);
  };

  const handleHeaderChange = (newFormState: FormReq) => {
    setFormState(newFormState);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Box
        sx={{
          position: 'sticky',
          top: '0',
          left: '0',
          zIndex: '100',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center', // Ensure alignment
          backgroundColor: 'white',
          borderBottom: 1,
          borderColor: 'divider',
          height: '80px', // Adjust height as needed
        }}
      >
        {/* Dropdown for form status */}
        <Box
          sx={{
            position: 'absolute',
            right: '20px', // Adjust as needed for left-center positioning
          }}
        >
          <TextField
            select
            label="Form Status"
            value={isActive ? 'Published' : 'Unpublished'}
            onChange={(e) => {
              setIsActive(e.target.value === 'Published');
              handleActive(e.target.value === 'Published');
            }}
            variant="outlined"
          >
            <MenuItem value="Published">Published</MenuItem>
            <MenuItem value="Unpublished">Unpublished</MenuItem>
          </TextField>
        </Box>

        {/* Tabs for editor and preview */}
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="form editor tabs">
          <Tab value={0} icon={<EditIcon />} iconPosition="start" label="Editor" />
          <Tab value={1} icon={<VisibilityIcon />} iconPosition="start" label="Preview" />
          {/* <Tab value={2} icon={<AssignmentIcon />} iconPosition="start" label="Collect responses" /> */}
        </Tabs>
      </Box>
      <TabPanel value={activeTab} index={0}>
        {/* Editor Content */}
        <Grid container spacing={1} direction="row" justifyContent="center">
          <Grid item md={6}>
            <Header
              key={formState.name}
              currentFormState={formState}
              handleHeaderChange={handleHeaderChange}
            />
            <div>
              {items.map((item, index) => (
                <div key={item.order}>
                  {renderElements(item, item.order ?? 0)}
                </div>
              ))}
            </div>
            <Grid container spacing={1} direction="row" justifyContent="center">
              <Grid item md={12} sx={{ mt: '10px', display: 'flex', justifyContent: 'end', gap: '20px' }}>
                <Box sx={{ mt: '10px' }}>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <div>
                      <Tooltip title="Add question" aria-label="add-element">
                        <Button
                          color="primary"
                          variant="contained"
                          aria-controls={open ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={handleMenuClick}
                        >
                          Add question
                        </Button>
                      </Tooltip>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        {formTypes.map((item) => (
                          <MenuItem
                            key={item.id}
                            onClick={() => handleMenuClose(item)}
                          >
                            <ListItemIcon>
                              {getIcon(item.id)}
                            </ListItemIcon>
                            <ListItemText>{item.value}</ListItemText>
                          </MenuItem>
                        ))}
                      </Menu>
                    </div>
                  </ClickAwayListener>
                </Box>
                <Box sx={{ mt: '10px', textAlign: 'right' }}>
                  <Tooltip title="Save form" aria-label="add-element">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleSubmit}
                    >
                      Save form
                    </Button>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <FormPreview
          key={formState.name}
          currentFormState={formState}
          currentFieldStates={fieldStates}
        />
      </TabPanel>
    </Fragment>
  );
};

interface TabPanelProps {
  children?: ReactNode;
  value: number;
  index: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) =>
  (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(FormBuilder);
