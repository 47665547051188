import React, { useState, Fragment } from 'react';
import {
  Grid,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  RadioGroup,
  Radio
} from '@mui/material';
import { FormQuestionMultiChoiceReq, FormQuestionSingleChoiceReq } from '../../proxy/proxy';

interface SingleChoiceInputPreviewProps {
  currentFieldState: FormQuestionMultiChoiceReq;
}

const SingleChoiceInputPreview: React.FC<SingleChoiceInputPreviewProps> = ({ currentFieldState }) => {
  const [fieldState, setFieldState] = useState<FormQuestionSingleChoiceReq>(currentFieldState);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [error, setError] = useState('');

  const validateInput = (value: string) => {
    if (fieldState.required && !value) {
      setError('Please select an option');
    } else {
      setError('');
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSelectedValue(value);
    validateInput(value);
  };

  return (
    <Fragment>
      <Grid item md={12} xs={12}>
        <Typography variant="h4" gutterBottom>
          {fieldState.title}
        </Typography>
        <FormControl component="fieldset" error={!!error} required={fieldState.required}>
          <RadioGroup value={selectedValue} onChange={handleChange}>
            {fieldState.choices?.map((choice, index) => (
              <FormControlLabel
                key={index}
                control={<Radio value={choice} />}
                label={choice}
              />
            ))}
          </RadioGroup>
          {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      </Grid>
    </Fragment>
  );
};

export default SingleChoiceInputPreview;
